import React, { useState, useContext } from "react";
import styled from "styled-components";
import ListGroup from "react-bootstrap/ListGroup";
import Collapse from "react-bootstrap/Collapse";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import { Link } from "gatsby";

import GlobalContext from "../../context/GlobalContext";
import { isExternalLink } from "../../utils";

const NestedMenuContainer = styled.div`
	a {
		color: ${({ theme }) => theme.colors.text};
		transition: all 0.3s ease-out;
		&:hover,
		&:focus,
		&:active {
			color: ${({ theme }) => theme.colors.light} !important;
			text-decoration: none;
		}
	}

	.list-group-item {
		background-color: ${({ theme }) => theme.colors.dark};
		& + .collapse:not(.show) {
			.list-group-item {
				border: none !important;
				border-width: 0 !important;
			}
		}
		& + .collapse.show {
			.list-group-item {
				&:first-child {
					border-top: none !important;
				}
			}
		}
	}
	.collapse + .list-group-item {
		border-top-width: 0;
	}
	/* .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  } */
`;

const MenuItem = ({
	label,
	isExternal = false,
	name,
	href,
	items,
	depthStep = 20,
	depth = 0,
	visible,
	...rest
}) => {
	const [open, setOpen] = useState(false);
	const hasSubItems = Array.isArray(items);

	const gContext = useContext(GlobalContext);
	return (
		<>
			{hasSubItems ? (
				<ListGroup.Item
					{...rest}
					css={`
						padding-left: ${depth * depthStep}px !important;
						cursor: pointer;
					`}
					onClick={() => setOpen(!open)}
					className="d-flex align-items-center justify-content-between"
				>
					<span>{label}</span>
					<span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
				</ListGroup.Item>
			) : (
				<ListGroup.Item
					{...rest}
					css={`
						padding-left: ${depth * depthStep}px !important;
					`}
				>
					{isExternalLink(href) ? (
						<a
							href={href}
							onClick={() => {
								if (gContext.visibleOffCanvas) {
									gContext.toggleOffCanvas();
								}
							}}
							tabIndex={!visible && "-1"}
						>
							{label}
						</a>
					) : (
						<Link
							to={href}
							onClick={() => {
								if (gContext.visibleOffCanvas) {
									gContext.toggleOffCanvas();
								}
							}}
							tabIndex={!visible && "-1"}
						>
							{label}
						</Link>
					)}
				</ListGroup.Item>
			)}

			{hasSubItems ? (
				<Collapse in={open}>
					<ListGroup>
						{items.map((subItem) => (
							<MenuItem
								key={subItem.name}
								depth={depth + 1}
								depthStep={depthStep}
								{...subItem}
							/>
						))}
					</ListGroup>
				</Collapse>
			) : null}
		</>
	);
};

const NestedMenu = ({ menuItems, visible }) => {
	return (
		<NestedMenuContainer>
			<ListGroup variant="flush">
				{menuItems?.map((menuItem, index) => (
					<MenuItem
						key={`${menuItem.name}${index}`}
						depthStep={20}
						depth={0}
						visible={visible}
						{...menuItem}
					/>
				))}
			</ListGroup>
		</NestedMenuContainer>
	);
};

export default NestedMenu;
