import React from "react";
import styled from "styled-components";
import {
	color,
	background,
	space,
	border,
	typography,
	shadow,
	flexbox,
	layout,
} from "styled-system";

// Utility to filter out unwanted props from being passed to the DOM
const shouldForwardProp = (prop) => !['borderColor'].includes(prop);

const ButtonSolid = styled.button.withConfig({ shouldForwardProp })`
	padding: 0.7rem 1.8rem;
	min-width: 150px;
	border-radius: 5px;
	font-size: 1em;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	outline: none !important;
	white-space: nowrap;
	transition: background-color 0.7s, color 0.7s;
	${color};
	${background};
	${border};
	${space};
	${typography};
	${shadow};
	${flexbox};
	${layout};
	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.colors.primary};
		background-color: ${({ theme }) => theme.colors.light};
	}
`;

const ButtonOutline = styled(ButtonSolid).withConfig({ shouldForwardProp })`
	background: transparent;
	font-size: 1.2em;
	border-color: ${({ theme, borderColor }) => theme.colors[borderColor]};
	&:hover,
	&:focus,
	&:active {
		color: ${({ theme }) => theme.colors.dark};
		background-color: ${({ theme }) => theme.colors.light};
	}
`;

const Button = ({
	variant = "solid",
	color = "light",
	bg = "primary",
	borderColor = "border",
	...rest
}) => {
	return variant === "solid" ? (
		<ButtonSolid
			onMouseDown={(e) => e.preventDefault()}
			color={color}
			border={`2px solid`}
			borderColor={bg}
			bg={bg}
			type="button"
			{...rest}
		/>
	) : (
		<ButtonOutline
			onMouseDown={(e) => e.preventDefault()}
			color={color}
			bg={bg}
			border={`2px solid`}
			borderColor={borderColor}
			type="button"
			{...rest}
		/>
	);
};

export default Button;
