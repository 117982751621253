import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.bg} !important;

    color: ${({ theme }) => theme.colors.text} !important;
  }

  p, .p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  ul,.ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.text};
    transition: all 0.3s ease-out;
    &:hover, &:active, &:focus{
      color: ${({ theme }) => theme.colors.secondary} !important;
      text-decoration: none!important;
      outline: none !important;
    }
  }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

  /* Based on the Figma design we don't need this */
  @media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
    }
  }

  input,
  textarea,
  select {
    padding: 8px;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.colors.bgdarkest};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 5px;
    font-size: 16px;
  }

  button {
    padding: 0.7rem 1.8rem;
    min-width: 150px;
    border-radius: 5px !important;
    font-size: 1em;
    font-weight: 400;
    overflow: hidden;
    outline: none !important;
    white-space: nowrap;
    transition: background-color 0.7s, color 0.7s;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};
    padding: 10px 20px;
  }

  button:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }

  select {
    cursor: context-menu;
  }

  label {
    cursor: pointer;
  }
`;

export default globalStyle;
