import React from "react";
import styled from "styled-components";
import { background } from "styled-system";

const Circle = styled(({ size, children, ...props }) => (
	<div {...props}>{children}</div>
))`
	height: ${({ size }) => size ?? 14}px;
	width: ${({ size }) => size ?? 14}px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.secondary};
	${background}
`;

export default Circle;
