exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ambassador-js": () => import("./../../../src/pages/ambassador.js" /* webpackChunkName: "component---src-pages-ambassador-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fees-js": () => import("./../../../src/pages/fees.js" /* webpackChunkName: "component---src-pages-fees-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-launchpad-js": () => import("./../../../src/pages/launchpad.js" /* webpackChunkName: "component---src-pages-launchpad-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-processing-times-js": () => import("./../../../src/pages/processing-times.js" /* webpackChunkName: "component---src-pages-processing-times-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-vasp-api-js": () => import("./../../../src/pages/vasp-api.js" /* webpackChunkName: "component---src-pages-vasp-api-js" */),
  "component---src-pages-verification-center-js": () => import("./../../../src/pages/verification-center.js" /* webpackChunkName: "component---src-pages-verification-center-js" */)
}

