import styled from "styled-components";
import { space, typography } from "styled-system";

import { device } from "../../utils";

export const ContentContainer = styled.div`
	margin-top: 10%;
	@media ${device.lg} {
		margin-top: 80px;
	}
	${space}
	${typography}
`;
