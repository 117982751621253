import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaXTwitter, FaTelegram, FaDiscord, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa6";
import { Title, Box } from "../Core";
import { footerLinks } from "./footerLinks";
import { isExternalLink, SOCIAL_LINKS, device } from "../../utils";
import Logo from "../Logo";

const TitleStyled = styled(Title)`
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      display: inline-block;
      line-height: 24px;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.lightShade} !important;
      }
    }
  }
`;

const PStyled = styled.p`
  margin-block: 24px 0;
  padding-bottom: 48px;
  color: #99a5d8;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
  letter-spacing: -0.03125rem;
`;

const CopyRightArea = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.border}`};
  padding-top: 18px;
  p {
    color: ${({ dark, theme }) => (dark ? theme.colors.lightShade : theme.colors.darkShade)};
    font-size: 0.8125rem;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) => (dark ? theme.colors.light : theme.colors.dark)} !important;
      font-size: 1rem;
      transition: 0.4s;
      padding: 16px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.secondary} !important;
      }
      @media ${device.lg} {
        padding: 4px 8px;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      <Box bg={isDark ? "bgdarker" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="12" md="12" className="mt-5 mt-lg-0">
                <Row>
                  <Col xs="6" lg="2">
                    <Logo />
                  </Col>
                  {footerLinks.map((footerSection) => (
                    <Col xs="6" lg="2" key={footerSection.title}>
                      <div className="mb-5 mb-lg-4">
                        <TitleStyled variant="card" color={isDark ? "light" : "dark"}>
                          {footerSection.title}
                        </TitleStyled>
                        <UlStyled color={isDark ? "secondary" : "darkShade"}>
                          {footerSection.links.map((link) => (
                            <li key={link.caption}>
                              {isExternalLink(link.href) ? (
                                <a href={link.href} target="_blank" rel="noreferrer">
                                  {link.caption}
                                </a>
                              ) : (
                                <Link to={link.href}>{link.caption}</Link>
                              )}
                            </li>
                          ))}
                        </UlStyled>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Box>

          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-start text-start mb-2 mb-sm-0">
                <p>&copy; 2018-{new Date().getFullYear()} Ping Exchange</p>
              </Col>
              <Col sm="6" className="text-sm-end text-end ">
                <ul>
                  <li>
                    <a href={SOCIAL_LINKS.twitter} target="_blank" rel="noreferrer" title="X (Twitter)" aria-label="Follow us on X (Twitter)">
                      <FaXTwitter />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_LINKS.telegram} target="_blank" rel="noreferrer" title="Telegram" aria-label="Follow us on Telegram">
                      <FaTelegram />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_LINKS.discord} target="_blank" rel="noreferrer" title="Discord" aria-label="Follow us on Discord">
                      <FaDiscord />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_LINKS.facebook} target="_blank" rel="noreferrer" title="Facebook" aria-label="Follow us on Facebook">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_LINKS.instagram} target="_blank" rel="noreferrer" title="Instagram" aria-label="Follow us on Instagram">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_LINKS.linkedin} target="_blank" rel="noreferrer" title="LinkedIn" aria-label="Follow us on LinkedIn">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_LINKS.youtube} target="_blank" rel="noreferrer" title="Youtube" aria-label="Follow us on YouTube">
                      <FaYoutube />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>

          <PStyled>
            SO-FIT is a self-regulatory organization approved by the Swiss Federal Supervisory Authority financial
            markets (FINMA) for the supervision of financial intermediaries referred to in Article 2 paragraph 3 of the
            Swiss Federal Law concerning the fight against money laundering and the financing of terrorism in the
            financial sector (AMLA).
          </PStyled>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
