import React, { useState } from "react";

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
	const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
	const [headerDark, setHeaderDark] = useState(false);
	const [footerDark, setFooterDark] = useState(true);

	const toggleOffCanvas = () => {
		setVisibleOffCanvas(!visibleOffCanvas);
	};

	const closeOffCanvas = () => {
		setVisibleOffCanvas(false);
	};

	const goHeaderDark = () => {
		setHeaderDark(true);
	};
	const goHeaderLight = () => {
		setHeaderDark(false);
	};

	const goFooterDark = () => {
		setFooterDark(true);
	};
	const goFooterLight = () => {
		setFooterDark(false);
	};

	return (
		<GlobalContext.Provider
			value={{
				headerDark,
				goHeaderDark,
				goHeaderLight,
				footerDark,
				goFooterDark,
				goFooterLight,
				visibleOffCanvas,
				toggleOffCanvas,
				closeOffCanvas,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

export default GlobalContext;
export { GlobalProvider };
