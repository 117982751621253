import React, { useContext, Fragment } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { navigate, Link } from "gatsby";
import { HiMenu } from "react-icons/hi";

import GlobalContext from "../../context/GlobalContext";
import Offcanvas from "../Offcanvas";
import NestedMenu from "../NestedMenu";
import { EXTERNAL_LINKS, device, isExternalLink } from "../../utils";
import Logo from "../Logo";
import { Button } from "../Core";
import { menuItems } from "./menuItems";

import "./header.scss";

const ToggleButton = styled.button`
	color: ${({ dark, theme }) =>
		dark ? theme.colors.secondary : theme.colors.darkShade}!important;
	border-color: ${({ dark, theme }) =>
		dark ? theme.colors.secondary : theme.colors.darkShade}!important;
	&:hover {
		color: ${({ dark, theme }) =>
			dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
		border-color: ${({ dark, theme }) =>
			dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
	}
`;
const NavContainer = styled.div`
	justify-content: flex-end;
`;

const Menu = styled.ul`
	@media ${device.lg} {
		display: flex;
		justify-content: flex-end;
	}
	.dropdown-toggle {
		cursor: pointer;
	}
	> li {
		> .nav-link {
			@media ${device.lg} {
				color: ${({ theme }) => theme.colors.light}!important;
				font-size: 1em;
				font-weight: 400;
				padding-left: 18px !important;
				padding-right: 18px !important;
			}
			&:hover,
			&:focus,
			&:active {
				color: ${({ theme }) => theme.colors.primary} !important;
			}
		}
	}
	.nav-item.dropdown {
		@media ${device.lg} {
			position: relative;
			z-index: 99;
		}
		&:hover {
			> .menu-dropdown {
				@media ${device.lg} {
					top: 90%;
					opacity: 1;
					pointer-events: visible;
				}
			}
		}
	}
`;

const Header = () => {
	const gContext = useContext(GlobalContext);

	const handleLogin = () => {
		navigate(EXTERNAL_LINKS.ping_app);
	};

	return (
		<>
			<header>
				<Container>
					<nav
						className={`navbar fixed-top site-navbar offcanvas-active navbar-expand-lg navbar-dark`}
					>
						<div className="container">
							<Logo />
							<ToggleButton
								className={`navbar-toggler ${
									gContext.visibleOffCanvas ? "collapsed" : ""
								}`}
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#menu"
								aria-controls="menu"
								aria-expanded="false"
								aria-label="Toggle navigation"
								onClick={gContext.toggleOffCanvas}
								dark
							>
								<HiMenu />
							</ToggleButton>
							<NavContainer className="collapse navbar-collapse" id="menu">
								<Menu className="navbar-nav" dark>
									{menuItems.map(({ label, name, href, ...rest }, index) => {
										return (
											<Fragment key={name + index}>
												<li className="nav-item" {...rest}>
													{isExternalLink(href) ? (
														<a
															className="nav-link"
															href={href}
															target="_blank"
															rel="noopener noreferrer"
														>
															{label}
														</a>
													) : (
														<Link
															className="nav-link"
															to={href}
															role="button"
															aria-expanded="false"
														>
															{label}
														</Link>
													)}
												</li>
											</Fragment>
										);
									})}
								</Menu>
								<div className="header-btns ms-auto ms-lg-0 d-none d-md-block">
									<Button className="login-button" onClick={handleLogin}>
										Go to exchange
									</Button>
								</div>
							</NavContainer>
						</div>
					</nav>
				</Container>
			</header>
			<Offcanvas
				show={gContext.visibleOffCanvas}
				onHideOffcanvas={gContext.toggleOffCanvas}
			>
				<NestedMenu menuItems={menuItems} visible={gContext.visibleOffCanvas} />
			</Offcanvas>
		</>
	);
};
export default Header;
