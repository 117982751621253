import { EXTERNAL_LINKS, SOCIAL_LINKS } from "../../utils";

export const footerLinks = [
  {
    title: "About us",
    links: [
      {
        caption: "About Ping",
        href: "/about",
      },
      {
        caption: "Ping Explore",
        href: EXTERNAL_LINKS.ping_blog,
      },
      {
        caption: "Terms of Service",
        href: "/terms-of-service",
      },
      {
        caption: "Privacy Policy",
        href: "/privacy-policy",
      },
    ],
  },
  {
    title: "Ecosystem",
    links: [
      {
        caption: "Exchange",
        href: EXTERNAL_LINKS.ping_app,
      },
      {
        caption: "Referral Program",
        href: "/referral",
      },
      {
        caption: "Core Blockchain (XCB)",
        href: "https://coreblockchain.net/",
      },
      {
        caption: "Core Token (CTN)",
        href: "https://coretoken.net/",
      },
      {
        caption: "CorePass",
        href: "https://corepass.net/",
      },
      {
        caption: "Blockchain Hub",
        href: SOCIAL_LINKS.blockchain_hub,
      },
      {
        caption: "Payto Link Generator",
        href: "https://payto.money/",
      },
    ],
  },
  {
    title: "Support",
    links: [
      {
        caption: "Contact Us",
        href: "/contact",
      },
      {
        caption: "Help center",
        href: EXTERNAL_LINKS.ping_help,
      },
      {
        caption: "Bug Bounty",
        href: "https://app.hacktrophy.com/projects/ping",
      },
      {
        caption: "Fees",
        href: "/fees",
      },
    ],
  },
  {
    title: "Learn more",
    links: [
      {
        caption: "Ping Blog",
        href: EXTERNAL_LINKS.ping_blog,
      },
      {
        caption: "Academy",
        href: EXTERNAL_LINKS.ping_academy,
      },
      {
        caption: "Videos",
        href: "https://www.youtube.com/@pingexchange",
      },
      {
        caption: "Tutorials",
        href: EXTERNAL_LINKS.ping_blog_tutorial,
      },
      {
        caption: "Status",
        href: EXTERNAL_LINKS.ping_status,
      },
      {
        caption: "API Documentation",
        href: EXTERNAL_LINKS.ping_api_documentation,
      },
      {
        caption: "VASP API",
        href: "/vasp-api",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        caption: "X (Twitter)",
        href: SOCIAL_LINKS.twitter,
      },
      {
        caption: "Telegram",
        href: SOCIAL_LINKS.telegram,
      },
      {
        caption: "Discord",
        href: SOCIAL_LINKS.discord,
      },
      {
        caption: "Facebook",
        href: SOCIAL_LINKS.facebook,
      },
      {
        caption: "Instagram",
        href: SOCIAL_LINKS.instagram,
      },
      {
        caption: "LinkedIn",
        href: SOCIAL_LINKS.linkedin,
      },
    ],
  },
];
