import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow, maxWidth } from "styled-system";

import {device} from "../../utils";

const Paragraph = styled.p`
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.1em;
  @media ${device.md} {
    font-size: 1.3125rem;
    line-height: 1.5em;
  }
  ${color};
  ${space};
  ${typography};
  ${shadow};
  ${maxWidth};
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 1rem;
  letter-spacing: -0.5px;
  line-height: 1.75em;
  ${color};
  ${space};
  ${typography};
  ${shadow};
  ${maxWidth};
`;

const Text = ({ variant, ...props }) => {
  let TextRender;

  switch (variant) {
    case "small":
      TextRender = ParagraphSmall;
      break;
    default:
      TextRender = Paragraph;
  }

  return <TextRender color="text" {...props} />;
};

export default Text;
