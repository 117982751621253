import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";

const Paragraph = styled.a`
	font-size: 20px;
	font-weight: 400;
	letter-spacing: -0.66px;
	line-height: 35px;
	${color};
	${space};
	${typography};
	${shadow};
	&:hover,
	&:active,
	&.active {
		text-decoration: none;
		${color};
	}
`;

const A = ({ href = "#", ...props }) => {
	return <Paragraph href={href} color="secondary" {...props} />;
};

export default A;
