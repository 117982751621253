import { rgba } from "polished";
import { breakpoints } from "./breakpoints";

const defaultColors = {
	primary: "#3749E7",
	secondary: "#99A5D8",
	lighter: "#A1ABFF",
	white: "#ffffff",
	black: "#000000",
	dark: "#0D1544",
	dark2: "#141B4F",
	dark3: "#060E3B",
	dark4: "#080a1b",
	bright_red: "#F93A59",
	bright_green: "#51E5A3",
	dark_shade_blue: "#394887",
	light_shade_blue: "#ACB5FF",
	info: "#0083ff",
};

const colors = {
	primary: defaultColors.primary,
	secondary: defaultColors.secondary,
	light: defaultColors.white,
	lightShade: rgba(defaultColors.white, 0.75),
	lightShadeBlue: defaultColors.light_shade_blue,
	dark: defaultColors.dark,
	darkShade: defaultColors.dark2,
	bg: defaultColors.dark,
	bgdarker: defaultColors.dark3,
	bgdarkest: defaultColors.dark4,
	border: defaultColors.dark_shade_blue,
	shadow: rgba(defaultColors.dark2, 0.175),
	heading: defaultColors.white,
	text: defaultColors.secondary,
	warning: defaultColors.bright_red,
	success: defaultColors.bright_green,
	info: defaultColors.info,
};

const theme = {
	initialColorModeName: "dark",
	colors: colors,
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	breakpoints: [
		`${breakpoints.sm}px`,
		`${breakpoints.md}px`,
		`${breakpoints.lg}px`,
		`${breakpoints.xl}px`,
	],
};

export default theme;
