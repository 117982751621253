import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import PingHeaderLogo from "../../assets/images/svg/ping-logo.svg";

const LogoLink = styled(Link)`
	font-size: 1.3125rem !important;
	font-weight: 500;
`;

const Logo = ({
	height,
	className = "navbar-brand",
	nonFocusable,
	...rest
}) => {
	return (
		<LogoLink
			to="/"
			className={`${className}`}
			tabIndex={nonFocusable && "-1"}
			{...rest}
		>
			<img
				src={PingHeaderLogo}
				alt="Ping Exchange"
				width="35"
				height="35"
				className="d-inline-block align-text-middle"
			/>
			<span>Ping</span>
		</LogoLink>
	);
};

export default Logo;
