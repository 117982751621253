import React from "react";
import { Helmet } from "react-helmet";
import PingMetaThumbnail from "../../assets/images/png/ping-exchange-thumbnail.png";

import { useSiteMetadata } from "../../hooks";
import { SOCIAL_LINKS } from "../../utils";

/**
 * Retrieves the domain from a given URL.
 *
 * @param {string} url - The URL from which to extract the domain.
 * @return {string|null} The domain extracted from the URL, or null if the URL is invalid.
 */
const getDomainFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (e) {
    console.error(e.message);
    return null;
  }
}

/**
 * Extracts the handle from a given URL.
 *
 * @param {string} url - The URL from which to extract the handle.
 * @return {string|null} The handle extracted from the URL, or null if an error occurs.
 */
const getHandleFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    // Ensure the pathname is split into segments and remove any empty segments that may result from trailing slashes
    const segments = pathname.split('/').filter(Boolean);
    // The handle is the last segment of the path
    return `@${segments.pop()}`;
  } catch (e) {
    console.error(e.message);
    return null;
  }
}

const SEO = () => {
  const meta = useSiteMetadata();

	return (
		<Helmet>
			<html lang={meta.language} />
			<title>{meta.title}</title>
      <meta name="title" content={meta.title} />
			<meta name="description" content={meta.description} />
			<link rel="icon" type="image/svg+xml" href="/favicon.svg" />
			<link rel="canonical" href={meta.siteUrl} />

			{/* Open Graph meta tags */}
			<meta property="og:title" content={meta.title} />
			<meta property="og:url" content={meta.siteUrl} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={`${meta.siteUrl}${PingMetaThumbnail}`} />
			<meta property="og:description" content={meta.description} />
			<meta property="og:locale" content="en_US" />
      <meta name="og:type" content="website" />
      <meta name="og:site_name" content={meta.siteName} />

      {/* X (Twitter) meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={`${meta.siteUrl}${PingMetaThumbnail}`} />
      <meta name="twitter:site" content={getHandleFromUrl(SOCIAL_LINKS.twitter)} />
      <meta name="twitter:creator" content={getHandleFromUrl(SOCIAL_LINKS.twitter)} />
      <meta property="twitter:domain" content={getDomainFromUrl(meta.siteUrl)} />
      <meta property="twitter:url" content={meta.siteUrl} />
		</Helmet>
	);
};
export default SEO;
