import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";

const HeroTitle = styled.h1`
	font-weight: 700;
	font-size: 3rem;
	line-height: 1em;
  text-wrap: balance;
	margin-bottom: 30px;
	@media ${device.sm} {
		font-size: 3rem;
		line-height: 1.1em;
	}
	@media ${device.lg} {
		font-size: 4rem;
		line-height: 1.1em;
		margin-bottom: 30px;
	}
	@media ${device.xl} {
		font-size: 5rem;
		line-height: 1.1em;
	}
	${color};
	${space};
	${typography};
	${shadow};
`;

const SectionTitle = styled.h2`
	font-weight: 700;
	font-size: 2.875rem;
	line-height: 1.2em;
	margin-bottom: 16px;
	@media ${device.md} {
		font-size: 3.75rem;
		line-height: 1.2em;
	}
	@media ${device.lg} {
		margin-bottom: 30px;
	}
	${color};
	${space};
	${typography};
	${shadow};
`;

const CardTitle = styled.h3`
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1.1em;
	${color};
	${space};
	${typography};
	${shadow};
`;

const Title = ({ variant, ...rest }) => {
	let TitleStyled = SectionTitle;

	switch (variant) {
		case "card":
			TitleStyled = CardTitle;
			break;
		case "hero":
			TitleStyled = HeroTitle;
			break;
		default:
			TitleStyled = SectionTitle;
	}
	return <TitleStyled color="heading" {...rest} />;
};

export default Title;
