import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						language
						title
						description
						siteUrl
            siteName
					}
				}
			}
		`
	);
	return site.siteMetadata;
};
