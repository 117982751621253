import React, {
	useContext,
	useRef,
} from "react";

import { ThemeProvider } from "styled-components";

import Header from "../Header";
import Footer from "../Footer";
import Seo from "../SEO";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import "./bootstrap-custom.scss";
import "./default.scss";

// the full theme object
import { theme as baseTheme } from "../../utils";

const Layout = ({ children, pageContext }) => {
	const gContext = useContext(GlobalContext);

	// Navbar style based on scroll
	const eleRef = useRef();

	if (pageContext.layout === "bare") {
		return (
			<ThemeProvider theme={baseTheme}>
				<GlobalStyle />
				<Seo />
				<div className="site-wrapper overflow-hidden" ref={eleRef}>
					{children}
				</div>
			</ThemeProvider>
		);
	}

	return (
		<>
			<ThemeProvider theme={baseTheme}>
				<GlobalStyle />
				<Seo />
				<div className="site-wrapper overflow-hidden" ref={eleRef}>
					<Header isDark={gContext.headerDark} />
					{children}

					<Footer isDark={gContext.footerDark} />
				</div>
			</ThemeProvider>
		</>
	);
};

export default Layout;
