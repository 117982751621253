import React from "react";
import styled from "styled-components";
import BsTable from "react-bootstrap/Table";
import { color, typography, background } from "styled-system";

const TableRow = styled.tr`
  text-align: left;
  border: 2px solid ${({ theme }) => theme.colors.dark};
`;

const Th = styled.th`
  color: ${({ theme }) => theme.colors.light};
  padding: 22px !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.56px;
  border: inherit !important;
  ${typography};
`;

const Td = styled.td`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 22px !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.56px;
  border: inherit !important;
  ${typography};
  ${color};
  ${background};
`;

const StyledTableRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: #29326266;
  }
`;

export const Table = ({ data }) => {
  const tableHeaders = (
    <TableRow>
      {data?.headers.map((header) => (
        <Th>{header}</Th>
      ))}
    </TableRow>
  );

  const tableRows = data?.rows.map((row, index) => {
    return (
      <StyledTableRow key={index}>
        {row.map((column) => (
          <Td>{column}</Td>
        ))}
      </StyledTableRow>
    );
  });

  return (
    <BsTable>
      <thead>{tableHeaders}</thead>
      <tbody>{tableRows}</tbody>
    </BsTable>
  );
};
