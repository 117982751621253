import { EXTERNAL_LINKS } from "../../utils";

export const menuItems = [
	{
		name: "trade",
		label: "Trade",
		href: EXTERNAL_LINKS.ping_app
	},
	{
		name: "markets",
		label: "Markets",
		href: EXTERNAL_LINKS.ping_markets
	},
	{
		name: "explore",
		label: "Explore",
		href: EXTERNAL_LINKS.ping_academy
	},
	{
		name: "help-center",
		label: "Help Center",
		href: "/help-center",
	},
];
